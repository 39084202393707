import { LinkProps } from 'next/link'
import { XEN_HOSTNAME } from 'utils/config'

export enum NavBarActiveKey {
  ROOT,
  CHANNEL,
  CHANNEL_INTEREST,
  COMMUNITY,
  INTERN,
  RECRUIT,
  ACTIVITY,
  CONTEST,
  EDUCATION,
  CLUB,
  CALENDAR,
  COVER_LETTER,
  SELF_INTRODUCTION,
}

export type TDesktopHeader = {
  size: 'small' | 'large'
  isSticky?: boolean
  searchBar?: boolean
  externalNavBar?: boolean
}

export type TMobileHeader = {
  isSticky?: boolean
  searchBar?: boolean
}

export type TNavBar = {
  isSticky?: boolean
  activeKey: NavBarActiveKey
}

export type TDesktopRenderOptions = {
  Header?: TDesktopHeader
  NavBar?: TNavBar
  Footer?: boolean
}

export type TMobileRenderOptions = {
  Header?: TMobileHeader
  NavBar?: TNavBar
  Footer?: boolean
  BottomFixedAd?: boolean
}

export enum NavBarBadge {
  NONE,
  NEW,
  COUNT,
}

export enum NavBarType {
  LINK,
  HOVER,
}

export const ACTIVITIES_TAB = [
  NavBarActiveKey.INTERN,
  NavBarActiveKey.RECRUIT,
  NavBarActiveKey.ACTIVITY,
  NavBarActiveKey.CONTEST,
  NavBarActiveKey.EDUCATION,
  NavBarActiveKey.CLUB,
]

export type TLinkMenuItem = {
  type: NavBarType.LINK
  label: string
  href: any
  active: NavBarActiveKey
  badge?: NavBarBadge
}

type THoverMenuItem = {
  type: NavBarType.HOVER
  label: string
  href: LinkProps['href']
  badge?: NavBarBadge
  active: NavBarActiveKey
  menuItems: TLinkMenuItem[]
}

type TNavBarMenuItems = (THoverMenuItem | TLinkMenuItem)[]

export const NAV_BAR_MENU_ITEMS: TNavBarMenuItems = [
  {
    type: NavBarType.HOVER,
    label: '채널',
    href: { pathname: '/channel' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CHANNEL,
    menuItems: [
      {
        type: NavBarType.LINK,
        label: '채널 홈',
        active: NavBarActiveKey.CHANNEL,
        href: { pathname: '/channel', query: { tab: 'home' } },
      },
      {
        type: NavBarType.LINK,
        label: '내 관심 소식',
        active: NavBarActiveKey.CHANNEL_INTEREST,
        href: { pathname: '/channel', query: { tab: 'interest' } },
      },
    ],
  },
  {
    type: NavBarType.LINK,
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/community` },
  },
  {
    type: NavBarType.HOVER,
    label: '실시간 공고',
    href: { pathname: '/list/intern' },
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.INTERN,
    menuItems: [
      {
        type: NavBarType.LINK,
        label: '인턴',
        href: { pathname: '/list/intern' },
        active: NavBarActiveKey.INTERN,
      },
      {
        type: NavBarType.LINK,
        label: '채용',
        href: { pathname: '/list/recruit' },
        active: NavBarActiveKey.RECRUIT,
      },
      {
        type: NavBarType.LINK,
        label: '대외활동',
        href: { pathname: '/list/activity' },
        active: NavBarActiveKey.ACTIVITY,
      },
      {
        type: NavBarType.LINK,
        label: '공모전',
        href: { pathname: '/list/contest' },
        active: NavBarActiveKey.CONTEST,
      },
      {
        type: NavBarType.LINK,
        label: '교육',
        href: { pathname: '/list/education' },
        active: NavBarActiveKey.EDUCATION,
      },
      {
        type: NavBarType.LINK,
        label: '동아리',
        href: { pathname: '/list/club' },
        active: NavBarActiveKey.CLUB,
      },
    ],
  },
  {
    type: NavBarType.LINK,
    label: '공고 달력',
    href: { pathname: '/calendar' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CALENDAR,
  },
  {
    type: NavBarType.LINK,
    label: '자소서 만능 검색기',
    href: { pathname: '/cover-letter/search' },
    badge: NavBarBadge.COUNT,
    active: NavBarActiveKey.COVER_LETTER,
  },
  {
    type: NavBarType.LINK,
    label: '자소서 작성',
    href: { pathname: '/self-introduction' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.SELF_INTRODUCTION,
  },
  {
    type: NavBarType.LINK,
    label: '멘토 게시판',
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/mentoring` },
  },
]

export const MOBILE_MENU_ITEMS = [
  {
    type: NavBarType.LINK,
    label: '홈',
    href: { pathname: '/' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.ROOT,
  },
  {
    type: NavBarType.LINK,
    label: '채널',
    href: { pathname: '/channel' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CHANNEL,
  },
  {
    type: NavBarType.LINK,
    label: '커뮤니티',
    active: NavBarActiveKey.COMMUNITY,
    href: { pathname: `https://${XEN_HOSTNAME}/community` },
  },
  {
    type: NavBarType.HOVER,
    label: '실시간 공고',
    href: { pathname: '/list/intern' },
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.INTERN,
  },
  {
    type: NavBarType.LINK,
    label: '공고 달력',
    href: { pathname: '/calendar' },
    badge: NavBarBadge.NONE,
    active: NavBarActiveKey.CALENDAR,
  },
  {
    type: NavBarType.LINK,
    label: '자소서 만능 검색기',
    href: { pathname: '/cover-letter/search' },
    badge: NavBarBadge.COUNT,
    active: NavBarActiveKey.COVER_LETTER,
  },
  {
    type: NavBarType.LINK,
    label: '자기소개서 관리',
    href: { pathname: '/self-introduction/manage' },
    badge: NavBarBadge.NEW,
    active: NavBarActiveKey.SELF_INTRODUCTION,
  },
]

export const MOBILE_SECOND_DEPTH_MENU_ITEMS = [
  {
    type: NavBarType.LINK,
    label: '인턴',
    href: { pathname: '/list/intern' },
    active: NavBarActiveKey.INTERN,
  },
  {
    type: NavBarType.LINK,
    label: '채용',
    href: { pathname: '/list/recruit' },
    active: NavBarActiveKey.RECRUIT,
  },
  {
    type: NavBarType.LINK,
    label: '대외활동',
    href: { pathname: '/list/activity' },
    active: NavBarActiveKey.ACTIVITY,
  },
  {
    type: NavBarType.LINK,
    label: '공모전',
    href: { pathname: '/list/contest' },
    active: NavBarActiveKey.CONTEST,
  },
  {
    type: NavBarType.LINK,
    label: '교육',
    href: { pathname: '/list/education' },
    active: NavBarActiveKey.EDUCATION,
  },
  {
    type: NavBarType.LINK,
    label: '동아리',
    href: { pathname: '/list/club' },
    active: NavBarActiveKey.CLUB,
  },
]
